import socketIOClient from "socket.io-client";

import { APIBASE_HOST, SOCKET_HOST, VOICE_API , HOST} from "../../../env";
import {currentToken, isUserLoggedIn, clearUserInfo} from "../v1/data_methods";


export function getVoiceAPI() {

        if(VOICE_API.indexOf("localhost") > -1) {

            return {
                url: VOICE_API,
                protocol: "http"
            
            }
        }

        return {
            url: VOICE_API,
            protocol: "https"
        };
    }

export function getWhapiLoginLink() {

        if(HOST.indexOf("localhost") > -1) {

            return 'https://semahub.localhost' + "/wabiz/login";
        }
    
        return 'https://' + HOST + "/wabiz/login";
    }

export function getSocket() {

    const token = currentToken();

    const socket = socketIOClient(SOCKET_HOST, {
        query: {
            token: token
        }
    });

    return socket;
}

export async function fetchDataJSON(path, useAuth = false) {
    
    const token = currentToken();

    if (useAuth && token === null) {
        return null;
    }

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
    };

    
    if (useAuth) {

        headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(url, {headers});

    if (!response.ok) {

        const stat = await response.text()

        if(stat.indexOf("Could not validate credentials") > -1) {

            clearUserInfo();

            window.location.href = "/login";

        }

        const result = {
            status: stat,
            data: null,
            Ok: false,
        }

        return result
    }

    const result = {
        status: 'Ok',
        data: await response.json(),
        Ok: true,
    }

    return result;
  }


  export const fetchPostJSON = async (path, data, useAuth = true) => {

    const token = currentToken();

    if (useAuth && token === null) {
        return null;
    }

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (useAuth) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        const stat = await response.text();

        if(stat.indexOf("Could not validate credentials") > -1) {

            clearUserInfo();

            window.location.href = "/login";

        }

        const result = {
            status: stat,
            data: null,
            Ok: false,
        }

        return result
    }

    const result = {
        status: 'Ok',
        data: await response.json(),
        Ok: true,
    }

    return result;
}

export const userLoginJSON = async (data) => {

    const path = 'token-auth';

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    });

    if (!response.ok) {

        const result = {
            status: await response.json(),
            data: null,
            Ok: false,
        }

        return result
    }

    const result = {
        status: 'OK',
        data: await response.json(),
        Ok: true,
    }

    return result;
}

export const userSignupJSON = async (data) => {

    const path = '/users/user-signup';

    const url = APIBASE_HOST + path;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    });

    if (!response.ok) {

        const result = {
            status: await response.text(),
            data: null,
            Ok: false,
        }

        return result
    }

    const result = {
        status: 'OK',
        data: await response.json(),
        Ok: true,
    }

    return result;
}